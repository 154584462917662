import React from 'react';
import './PlanesCardMobile.css';
import PeruFlag from '../../assets/Certificado.svg';

function PlanesCardMobile({
  title = "",
  badge = "",
  description = "",
  price = "",
  priceDetails = "",
  buttonLink = "#",
}) {
  return (
    <a
      href={buttonLink}
      target="_blank"
      rel="noopener noreferrer"
      className="plan-card-mobile"
    >
      <div className="plan-card-content">
        <div className="plan-card-header">
          <h3 className="plan-card-title">{title}</h3>
          <p className="plan-card-badge">{badge}</p>
        </div>
        <div className="plan-card-selector">
          <p>{description}</p>
        </div>
        <div className="plan-card-info">
          <div className="plan-card-price">
            <img src={PeruFlag} alt="country flag" className="plan-card-flag" />
            <p className="plan-card-amount">{price}</p>
          </div>
          <p className="plan-card-details">{priceDetails}</p>
        </div>
      </div>
    </a>
  );
}

export default PlanesCardMobile;

