import React from 'react';
import './PlanesCard.css';
import PeruFlag from '../../assets/Certificado.svg';

function PlanCard({
  title = "",
  badge = "",
  description = "",
  price = "",
  priceDetails = "",
  benefits = [
    "",
    "",
    "",
    "",
    "",
  ],
  nonBenefits = [
    "",
    "",
    "",
  ],
  buttonText = "",
  buttonLink = "#", // Default link value
}) {
  return (
    <div className="plan-card">
      <div className="plan-card-header">
        <h3 className="plan-card-title">{title}</h3>
        <p className="plan-card-badge">{badge}</p>
      </div>
      <div className="plan-card-selector">
        <p>{description}</p>
      </div>
      <div className="plan-card-info">
        <div className="plan-card-price">
          <img src={PeruFlag} alt="country flag" className="plan-card-flag" />
          <p className="plan-card-amount">{price}</p>
        </div>
        <p className="plan-card-details">{priceDetails}</p>
      </div>
      <div className="plan-card-benefits">
        <ul>
          {benefits.map((benefit, index) => (
            <li key={index}>
              <span className="benefit-icon">&#10003;</span> {benefit}
            </li>
          ))}
        </ul>
      </div>
      <div className="plan-card-nonbenefits">
        <ul>
          {nonBenefits.map((nonBenefit, index) => (
            <li key={index}>
              <span className="nonbenefit-icon">&#10007;</span> {nonBenefit}
            </li>
          ))}
        </ul>
      </div>
      <div className="plan-card-cta">
        {/* Updated to include a link */}
        <a href={buttonLink} target="_blank" rel="noopener noreferrer">
          <button className="plan-card-button">{buttonText}</button>
        </a>
      </div>
    </div>
  );
}

export default PlanCard;
