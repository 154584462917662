import React, { useEffect } from 'react';
import './Home.css';
import './HomeMobile.css';
import ProfesoresIngles from '../../assets/ProfesoresIngles.png';
import ChicoEstudiandoIngles from '../../assets/ChicoEstudiandoIngles.svg';
import ChicaEstudiandoIngles from '../../assets/ChicaEstudiandoIngles.svg';
import SecondaryNavbar from '../../components/secondaryNavbar/SecondaryNavbar';
import CoursesCard from '../../components/coursesCard/CoursesCard';
import AprendeSection from '../../components/aprende/AprendeSection';
import CursoSection from '../../components/cursoSection/CursoSection';
import ComunidadClaseIngles from '../../assets/ComunidadClaseIngles.svg';
import Footer from '../../components/footer/footer';
import { Link } from 'react-router-dom';

function Home() {
  useEffect(() => {
    const handleScroll = () => {
      const changeContainer = document.querySelector('.change-container');
      const secondaryNavbar = document.querySelector('.secondary-navbar');
      const previousSection = document.querySelector('.previous-section'); 

      if (!changeContainer || !previousSection) return;

      const sectionHeight = changeContainer.offsetHeight;
      const scrollPosition = window.scrollY;
      const offset = 600;
      const sectionTop = changeContainer.offsetTop - offset;
      const sectionBottom = sectionTop + sectionHeight;

      if (scrollPosition >= sectionTop && scrollPosition <= sectionBottom) {
        const scrollPercentage = (scrollPosition - sectionTop) / (sectionHeight - offset);

        let newColor, containerColor;

        if (scrollPercentage < 0.12) {
          const adjustedScrollPercentage = scrollPercentage / 0.4;
          const startColor = [255, 255, 255];
          const midColor = [44, 62, 80];
          newColor = startColor.map((start, index) =>
            Math.round(start + (midColor[index] - start) * adjustedScrollPercentage)
          );
          containerColor = `rgb(${newColor[0]}, ${newColor[1]}, ${newColor[2]})`;
        } else if (scrollPercentage < 0.8) {
          const adjustedScrollPercentage = (scrollPercentage - 0.4) / 0.4;
          const midColor = [44, 62, 80];
          const endColor = [32, 31, 48]; 
          newColor = midColor.map((start, index) =>
            Math.round(start + (endColor[index] - start) * adjustedScrollPercentage)
          );
          containerColor = `rgb(${newColor[0]}, ${newColor[1]}, ${newColor[2]})`;
        } else {
          containerColor = `rgb(32, 31, 48)`;
        }

        changeContainer.style.backgroundColor = containerColor;
        previousSection.style.backgroundColor = containerColor; // Transition the previous section as well
        document.body.style.backgroundColor = `rgb(255, 255, 255)`; 
      } else if (scrollPosition < sectionTop) {
        document.body.style.backgroundColor = 'rgb(255, 255, 255)';
        previousSection.style.backgroundColor = 'rgb(255, 255, 255)'; // Reset the previous section color
      } else if (scrollPosition >= sectionBottom) {
        changeContainer.style.backgroundColor = 'rgb(32, 31, 48)';
        previousSection.style.backgroundColor = 'rgb(255, 255, 255)'; // Ensure the previous section resets after transition
        document.body.style.backgroundColor = 'rgb(255, 255, 255)';
      }

      if (scrollPosition >= sectionTop && scrollPosition <= sectionBottom) {
        if (secondaryNavbar) {
          secondaryNavbar.classList.remove('animate__slideInDown');
          secondaryNavbar.classList.add('animate__animated', 'animate__slideOutUp');
        }
      } else {
        if (secondaryNavbar) {
          secondaryNavbar.classList.remove('animate__slideOutUp');
          secondaryNavbar.classList.add('animate__animated', 'animate__slideInDown');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="home-container">
        <div className="home-content flex flex-col md:flex-row">
          <img src={ProfesoresIngles} alt="Profesores de ingles" className="home-img"></img> 
          <div className="home-text">
            <h1 className="title">¡Aprende Inglés Rápido y Fácil por solo $1.00 con Englishmate!</h1>
            <div className="home-buttons">
              <button 
              className="button orange-button"
              onClick={() => window.open("https://wa.me/message/CYQZH2SKT227H1", "_blank")}>
                Matricúlate Ya
                </button>
              <button className="button blue-button"
              onClick={() => window.open("https://wa.me/message/CYQZH2SKT227H1", "_blank")}>
                Soy un alumno
              </button>
            </div>
            <p className="home-small-text">Conviértete en un mate, con Englishmate</p>
          </div>
        </div>
      </div>

      <SecondaryNavbar />

      <div className="section-container mt-20">
        <div className="section flex flex-col md:flex-row">
          <div className="section-text-right">
            <h2>Clases de Inglés a $1.00</h2>
            <p>
              Aprende <strong>Ingles a $1.00 Dolar</strong> con nuestro curso de inglés básico online, diseñado para inglés desde cero en 6 meses. Gracias a nuestros profesores capacitados, mejorarás tu pronunciación, ampliarás tu vocabulario y te prepararás para exámenes internacionales.
            </p>
            <Link to="/adultos" className="button orange-button section-button">
            Inglés Básico
            </Link>
          </div>
          <div className="section-image">
            <img src={ChicoEstudiandoIngles} alt="Exito en los estudios"></img>
          </div>
        </div>
      </div>

      <div className="section-container">
        <div className="section flex flex-col md:flex-row">
          <div className="section-image">
            <img src={ChicaEstudiandoIngles} alt="Dos jovenes estudiando de manera remota"></img>
          </div>
          <div className="section-text-left">
            <h2>Inglés para Jóvenes y Adultos</h2>
            <p>
              Nuestros cursos de inglés para jóvenes y adultos te sumergen en una experiencia de aprendizaje interactivo con <strong>clases en vivo</strong>. Diseñado para quienes buscan aprender inglés rápidamente y aplicar lo aprendido en situaciones reales.
            </p>
            <Link to="/adultos" className="button blue-button section-button">
              Jóvenes & Adultos
            </Link>
          </div>
        </div>
      </div>

      <section className="section-container previous-section mt-20">
        <div className="section flex flex-col md:flex-row">
          <div className="section-text-right">
            <h2>Aprende Inglés a solo $1.00 USD por clase</h2>
            <p>
              Potencia tu carrera y amplía tus oportunidades laborales con nuestros <strong>cursos de inglés online económicos</strong>. Desde nivel básico hasta avanzado, ganarás confianza al hablar inglés con profesores expertos y te prepararás para certificaciones internacionales.
            </p>
          </div>
          <div className="section-card">
            <CoursesCard 
              discountText="SOLO $1.OO🔥"
              courseTitle="Inglés Básico"
              duration="Tiempo: 6 meses / 156 horas"
              features={[
                'Aulas personalizadas',
                'Seguimiento al alumno',
                'Talleres gratuitos incluidos',
                'Speaking weekends (Taller de Speaking)',
              ]}
              plan="Plan 6 meses"
              originalPrice="S/. 720 PEN / $192 USD"
              specialOffer="Oferta Especial:"
              priceLabel="Ahora"
              price="$1.00 por clase"
              total="Total: $16.00/mes"
              buttonText="Ver curso"
              buttonLink="/adultos"
            />
          </div>
        </div>
      </section>

      <section className="change-container">
        <AprendeSection />
        <CursoSection/>
      </section>
      
      <section className="section-container mt-20">
        <div className="section flex flex-col md:flex-row">
          <div className="section-text-right">
            <h3>La Mejor Comunidad de Inglés</h3>
            <p>
            Únete a la comunidad de Englishmate, donde aprender inglés es mucho más que solo tomar clases. Disfruta de talleres gratuitos, sesiones de speaking, clases de retroalimentación, y actividades interactivas que hacen que el aprendizaje sea dinámico y divertido. Participa en juegos y desafíos que mejoran tu fluidez mientras te conectas con otros estudiantes que comparten tu pasión por dominar el idioma.
            </p>
            <button 
            className="button orange-button section-button"
            onClick={() => window.location.href = "https://discord.gg/NTWVSMVMpT"}>
              Únete a la comunidad</button>
          </div>
          <div className="section-image">
            <img src={ComunidadClaseIngles} alt="Estudiante participando en comunidad online de clases de ingles"/>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Home;
