import React from 'react';
import PlanCard from './PlanesCard'; // Desktop version
import PlanesCardMobile from './PlanesCardMobile'; // Mobile version
import './PlanesSection.css';

function PlanesSection() {
  return (
    <section className="planes-section">
      <h2 className="planes-section-title">Nuestros Planes</h2>

      {/* Desktop Plan Cards */}
      <div className="planes-section-cards desktop-only">
        <PlanCard
          title="Plan Mensual"
          badge="Mensual"
          description="Solo $1.00 Dolar por hora"
          price="S/99/mes"
          priceDetails="Cobro mensual recurrente"
          benefits={[
            "+8 horas semanales ",
            "Clases de conversación en vivo",
            "Acceso a material educativo",
            "Certificados digitales",
          ]}
          nonBenefits={[
            "Talleres: Work & Travel, Business English, Medical English y más",
            "Disponibilidad para acceder a becas",
            "Acceso a la bolsa de trabajo EnglishJobs",
          ]}
          buttonText="Elige Plan Mensual"
          buttonLink="https://api.whatsapp.com/message/CYQZH2SKT227H1?autoload=1&app_absent=0"
        />
        <PlanCard
          title="Plan Semestral"
          badge="Semestral"
          description="Ahorra hasta S/360 Soles"
          price="S/83/mes"
          priceDetails="Cobro cada 6 meses de S/499"
          benefits={[
            "+8 horas semanales ",
            "Clases de conversación en vivo",
            "Acceso a material educativo",
            "Certificados digitales",
            "Talleres: Work & Travel, Business English, Medical English y más",
            "Disponibilidad para acceder a becas",
            "Acceso a la bolsa de trabajo EnglishJobs",
          ]}
          nonBenefits={[]}
          buttonText="Elige Plan Semestral"
          buttonLink="https://api.whatsapp.com/message/CYQZH2SKT227H1?autoload=1&app_absent=0"
        />
        <PlanCard
          title="Plan Anual"
          badge="Anual"
          description="Ahorra hasta S/720 Soles"
          price="S/58/mes"
          priceDetails="Cobro anual de S/699"
          benefits={[
            "+8 horas semanales ",
            "Clases de conversación en vivo",
            "Acceso a material educativo",
            "Certificados digitales",
            "Talleres: Work & Travel, Business English, Medical English y más",
            "Disponibilidad para acceder a becas",
            "Acceso a la bolsa de trabajo EnglishJobs",
          ]}
          nonBenefits={[]}
          buttonText="Elige Plan Anual"
          buttonLink="https://api.whatsapp.com/message/CYQZH2SKT227H1?autoload=1&app_absent=0"
        />
      </div>

      {/* Mobile Plan Cards */}
      <div className="planes-section-cards mobile-only">
        <PlanesCardMobile
          title="Plan Mensual"
          badge="Mensual"
          description="Solo $1.00 Dolar por hora"
          price="S/119/mes"
          priceDetails="Cobro mensual recurrente"
          buttonLink="https://api.whatsapp.com/message/CYQZH2SKT227H1?autoload=1&app_absent=0"
        />
        <PlanesCardMobile
          title="Plan Semestral"
          badge="Semestral"
          description="Ahorra hasta S/360 Soles"
          price="S/83/mes"
          priceDetails="Cobro cada 6 meses de S/499"
          buttonLink="https://api.whatsapp.com/message/CYQZH2SKT227H1?autoload=1&app_absent=0"
        />
        <PlanesCardMobile
          title="Plan Anual"
          badge="Anual"
          description="Ahorra hasta S/720 Soles"
          price="S/58/mes"
          priceDetails="Cobro anual de S/699"
          buttonLink="https://api.whatsapp.com/message/CYQZH2SKT227H1?autoload=1&app_absent=0"
        />
      </div>
    </section>
  );
}

export default PlanesSection;

