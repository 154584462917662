import React, { useState } from 'react';
import './CursoPersonalizado.css';
import NivelesIcon from '../../assets/NivelesIcon.svg';
import ClasesConversacionIcon from '../../assets/ClasesConversacionIcon.svg';
import GruposReducidosIcon from '../../assets/GruposReducidosIcon.svg';
import ProfesoresExperienciaIcon from '../../assets/ProfesoresExperienciaIcon.svg';
import NivelesTab from '../../assets/NivelesTab.svg';
import ClasesTab from '../../assets/ClasesConversacionTab.svg';
import GruposTab from '../../assets/GruposReducidosTab.svg';
import ProfesoresTab from '../../assets/ProfesoresExperienciaTab.svg';

function CursoPersonalizado() {
    const [activeTab, setActiveTab] = useState("Tab 1");
  
    const tabs = [
      {
        id: "Tab 1",
        title: "3 niveles de conocimiento",
        description: "Cursos específicos según tu nivel de inglés",
        icon: NivelesIcon,
        img: NivelesTab,
      },
      {
        id: "Tab 2",
        title: "Clases de conversación online en vivo",
        description: "Cada curso tiene vocabulario específico según tu perfil profesional.",
        icon: ClasesConversacionIcon,
        img: ClasesTab,
      },
      {
        id: "Tab 3",
        title: "Grupos reducidos",
        description: "Cada grupo tendrá como máximo 20 estudiantes con intereses similares.",
        icon: GruposReducidosIcon,
        img: GruposTab,
      },
      {
        id: "Tab 4",
        title: "Profesores con experiencia",
        description: "El staff de profesores cuenta con años de experiencia y son especialistas en distintas áreas digitales.",
        icon: ProfesoresExperienciaIcon,
        img: ProfesoresTab,
      },
    ];
  
    return (
      <section id="verticalTab" className="courses_info w-tabs">
        <div className="courses_info-list w-tab-menu">
          {tabs.map((tab) => (
            <div
              key={tab.id}
              className={`info_container w-inline-block w-tab-link ${activeTab === tab.id ? "w--current" : ""}`}
              onClick={() => setActiveTab(tab.id)}
              role="button"
              tabIndex="0"
            >
              <div className="info_card">
                <img src={tab.icon} alt={`${tab.title} icon`} />
                <div className="info_card-txt">
                  <div className="text-size-large">{tab.title}</div>
                  <div className="text-size-medium">{tab.description}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="w-tab-content">
          {tabs.map((tab) => (
            <div key={tab.id} className={`w-tab-pane ${activeTab === tab.id ? "w--tab-active" : ""}`}>
              {activeTab === tab.id && <img src={tab.img} alt={tab.title} className="tab-image" />}
            </div>
          ))}
        </div>
      </section>
    );
  }
  
  export default CursoPersonalizado;