import React, { useState } from 'react';
import './Accordion.css'; 

const Accordion = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion">
      <div
        className={`accordion-question ${isOpen ? 'open' : ''}`}
        onClick={toggleAccordion}
      >
        <span>{question}</span>
        <div className="accordion-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="10"
            viewBox="0 0 18 10"
            fill="none"
          >
            <path
              d="M16.5 1L9 8.5L1.5 1"
              stroke="#41D3BE"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      {isOpen && (
        <div className="accordion-answer">
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
};

export default Accordion;
