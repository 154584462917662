import React, { useEffect } from 'react';
import './Adultos.css';
import SecondaryNavbar from '../../components/secondaryNavbar/SecondaryNavbar';
import CustomCard from '../../components/CustomCard/CustomCard';
import EstudiantesIngles from '../../assets/EstudiantesIngles.svg';
import ChicaIngles from '../../assets/ChicaIngles.png';
import ENGLISHMATE from '../../assets/ENGLISHMATE.svg'
import IELTS from '../../assets/IELTS.svg'
import TOEFL from '../../assets/TOEFL.svg'
import Accordion from '../../components/Accordion/Accordion';
import Footer from '../../components/footer/footer';
import CursoPersonalizado from '../../components/CursoPersonalizado/CursoPersonalizado';
import PlanesSection from '../../components/PlanesSection/PlanesSection';

function Adultos() {
  useEffect(() => {
    const handleScroll = () => {
      const changeContainer = document.querySelector('.change-container-adultos');
      const secondaryNavbar = document.querySelector('.secondary-navbar');
      const previousSection = document.querySelector('.previous-section'); 

      if (!changeContainer || !previousSection) return;

      const sectionHeight = changeContainer.offsetHeight;
      const scrollPosition = window.scrollY;
      const offset = 550;
      const sectionTop = changeContainer.offsetTop - offset;
      const sectionBottom = sectionTop + sectionHeight;

      const startColor = [255, 255, 255]; // White
      const midColor = [42, 57, 76]; // #2A394C
      const endColor = [32, 31, 48]; // #201F30

      if (scrollPosition >= sectionTop && scrollPosition <= sectionBottom) {
        const scrollPercentage = (scrollPosition - sectionTop) / (sectionHeight - offset);

        let newColor, containerColor;

        if (scrollPercentage < 0.35) { 
          // Transition from startColor to midColor
          const adjustedScrollPercentage = scrollPercentage / 0.35; 
          newColor = startColor.map((start, index) =>
            Math.round(start + (midColor[index] - start) * adjustedScrollPercentage)
          );
          containerColor = `rgb(${newColor[0]}, ${newColor[1]}, ${newColor[2]})`;
        } else if (scrollPercentage < 0.9) {
          // Transition from midColor to endColor
          const adjustedScrollPercentage = (scrollPercentage - 0.35) / (0.9 - 0.35); 
          newColor = midColor.map((start, index) =>
            Math.round(start + (endColor[index] - start) * adjustedScrollPercentage)
          );
          containerColor = `rgb(${newColor[0]}, ${newColor[1]}, ${newColor[2]})`;
        } else {
          containerColor = `rgb(${endColor[0]}, ${endColor[1]}, ${endColor[2]})`;
        }

        changeContainer.style.backgroundColor = containerColor;
        previousSection.style.backgroundColor = containerColor; 
        document.body.style.backgroundColor = `rgb(255, 255, 255)`; 
      } else if (scrollPosition < sectionTop) {
        document.body.style.backgroundColor = 'rgb(255, 255, 255)';
        previousSection.style.backgroundColor = 'rgb(255, 255, 255)'; 
      } else if (scrollPosition >= sectionBottom) {
        changeContainer.style.backgroundColor = `rgb(${endColor[0]}, ${endColor[1]}, ${endColor[2]})`;
        previousSection.style.backgroundColor = 'rgb(255, 255, 255)'; 
        document.body.style.backgroundColor = 'rgb(255, 255, 255)';
      }

      if (scrollPosition >= sectionTop && scrollPosition <= sectionBottom) {
        if (secondaryNavbar) {
          secondaryNavbar.classList.remove('animate__slideInDown');
          secondaryNavbar.classList.add('animate__animated', 'animate__slideOutUp');
        }
      } else {
        if (secondaryNavbar) {
          secondaryNavbar.classList.remove('animate__slideOutUp');
          secondaryNavbar.classList.add('animate__animated', 'animate__slideInDown');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <section className="adultos-container">
        <div className="adultos-content flex flex-col md:flex-row">
          <div className="adultos-text">
            <h1 className="adultos-title">Curso de Inglés para Jóvenes y Adultos</h1>
            <div className="adultos-buttons">
              <button 
                className="adultos-button adultos-orange-button"
                onClick={() => window.open("https://wa.me/message/CYQZH2SKT227H1", "_blank")}
              >
                Matricúlate Ya
              </button>
              <span className="adultos-price-text">Solo $1 dólar por clase</span>
            </div>
            <p className="adultos-students-count"><strong>+10,000</strong> alumnos en toda Latinoamérica</p>
            <p className="adultos-start-date">
              <span className="green-circle"></span> Inicio de clases 06 de Enero del 2025
            </p>
          </div>
          <img src={EstudiantesIngles} alt="Estudiantes de inglés" className="adultos-img" />
        </div>
      </section>
      
      <SecondaryNavbar />

      <section className='previous-section'>
        <div className="adultos-section2 mt-20">
          <div className="section flex flex-col md:flex-row">
            <div className="section-text-right">
              <h2>¿Por qué estudiar inglés con Englishmate?</h2>
              <p>
                En Englishmate aprenderás todo lo básico del inglés de forma rápida y efectiva. Te ofrecemos clases personalizadas con profesores nativos, grupos pequeños para mayor atención, y talleres gratuitos para mejorar tu fluidez. ¡Con nosotros, aprender inglés será más fácil y divertido!
              </p>
                <a 
                href="/Brochure_englishmate.pdf" 
                download
                className="button blue-button section-adultos-button"
              >
                Descargar Brochure
              </a>
            </div>
            <div className="section-image">
              <img src={ChicaIngles} alt="Éxito en los estudios" />
            </div>
          </div>
        </div>

        <h3 className="cards-section-title">Obtén certificaciones internacionales</h3>
        <div className="cards-container previous-section">
          <CustomCard 
            borderColor="#0C4187" 
            bottomTextColor="#0C4187"
            imageSrc={ENGLISHMATE}
            mainText="Certificación de la academia en cada nivel"
            bottomText="Certificado Englishmate"
          />
          <CustomCard 
            borderColor="#2C3E50" 
            bottomTextColor="#2C3E50"
            imageSrc={TOEFL}
            mainText="Acceso a través de examen, preparación incluida"
            bottomText="Certificación Internacional"
          />
          <CustomCard 
            borderColor="#2C3E50" 
            bottomTextColor="#2C3E50"
            imageSrc={IELTS}
            mainText="Acceso a través de examen, preparación incluida"
            bottomText="Certificación Internacional"
          />
        </div>
      </section>

      <section className="change-container-adultos">
        <CursoPersonalizado />
        <PlanesSection />
      </section>
      
      <section className="faq-section">
      <h2 className="faq-heading">Preguntas Frecuentes</h2>
      <Accordion
        question="¿Qué niveles cubre el curso?"
        answer="Nuestro curso es completo y está diseñado para llevarte desde nivel básico hasta nivel avanzado en solo 1 año. No necesitas conocimientos previos para comenzar."
      />
      <Accordion
        question="¿Cuánto dura el curso?"
        answer="El curso tiene una duración de 1 año, con un enfoque progresivo que abarca desde lo básico hasta lo avanzado."
      />
      <Accordion
        question="¿Las clases son presenciales u online?"
        answer="Todas nuestras clases son 100% online, lo que te permite aprender desde la comodidad de tu hogar y en horarios flexibles."
      />
      <Accordion
        question="¿Recibiré un certificado al terminar el curso?"
        answer="Sí, al finalizar recibirás un certificado que acredita tu dominio del inglés en nivel avanzado."
      />
      <Accordion
        question="¿Cuál es la metodología de enseñanza?"
        answer="Nuestra metodología combina CLT (Communicative Language Teaching) y TBL (Task-Based Learning), enfocándose en desarrollar tus habilidades comunicativas a través de actividades prácticas y tareas reales. Aprenderás a usar el inglés de manera efectiva en situaciones cotidianas, profesionales y académicas, asegurando un progreso dinámico y significativo."
      />
      <Accordion
        question="¿Qué métodos de pago aceptan?"
        answer="Aceptamos pagos mediante Yape, Plin, transferencias bancarias y PayPal."
      />

    </section>

      <Footer />
    </div>
  );
}

export default Adultos;
