import React from 'react';
import './CustomCard.css';

function CustomCard({ 
  borderColor = '#3498db', // default border color (blue)
  bottomTextColor = '#3498db', // default color for bottom text box
  imageSrc,
  mainText = 'Certificación de la academia en cada nivel',
  bottomText = 'Certificado Cultural'
}) {
  return (
    <div className="custom-card" style={{ borderColor }}>
      <img src={imageSrc} alt="Card icon" className="card-image" />
      <div className="card-content">
        <p className="main-text">{mainText}</p>
      </div>
      <div className="bottom-text" style={{ backgroundColor: bottomTextColor }}>
        {bottomText}
      </div>
    </div>
  );
}

export default CustomCard;
